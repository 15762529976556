









import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Input extends Vue {
  @Prop(Array) readonly items!: object;
  @Prop(String) readonly label!: string;

  @Model("input", { type: String }) readonly data!: string;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  localData: string;

  constructor() {
    super();

    this.localData = this.data;
  }
}
